import React from 'react';
import Layout from '../layout/layout';
import Seo from '../layout/seo';
import PricingContent from '../content/pricing/pricingcontent';

class Pricing extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo lang="de" title="Kosten" location={this.props.location} />
        <PricingContent />
      </Layout>
    )
  }
}

export default Pricing;